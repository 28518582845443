import React from "react"
import { Container, Box, Heading, Button, Text } from "@chakra-ui/react"

import { useContent } from "@app/hooks/useContent"
import { useCore } from "@app/hooks/useCore"
import Link from "@app/components/Link"
import type { Props } from "@app/pages/404"
import type { PageProps } from "@root/types/global"
import { useRoutes } from "@app/hooks/useRoutes"

const Error: React.FC<PageProps<Props>> = ({ page }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const { urlResolver } = useRoutes()

  const content = useContent(page)
  const linkUrl = urlResolver(page?.button?.document)

  return isBrowser ? (
    <Container>
      <Box as="section" py={{ base: "8", lg: "16" }} textAlign="center">
        <Heading as="h1" mb={4} color="typography.headlines900">
          {page?.title}
        </Heading>

        <Text as={Box} mb={8}>
          {content}
        </Text>

        <Button as={Link} to={linkUrl.url} variant="solidSecondary">
          {page?.button?.title}
        </Button>
      </Box>
    </Container>
  ) : null
}

export default Error
